<template>
  <div class="cbox">
    <div class="headbar">
      <div style="float: right;display: flex;">

        <tchtree v-model="class_id" @change="getData"></tchtree>

        
        <el-button type="primary" plain size="small" icon="el-icon-download" @click="downShengyuan">下载导入91job的生源表</el-button>




      </div>
      <div style="padding-top: 14px">
        <el-breadcrumb>
          <el-breadcrumb-item>系统对接</el-breadcrumb-item>
          <el-breadcrumb-item>联合学院</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
    </div>
    <div class="bcontent">
    </div>
  </div>
</template>
<script>
import api from '@/api/api';
import tchtree from '../com/tchTree.vue'
export default {
  name: 'lianhexueyuan',
  components: {
    tchtree
  },
  data() {
    return {
      class_id:""
    }
  },
  mounted() {

  },
  methods: {
    getData(){},
    downShengyuan(){}
  }
}
</script>
<style type="text/less"></style>
